<template>
    <v-layout justify-center>
        <v-flex xs12 sm8 md4>
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <v-text-field outline clearable required
                        v-if="pass === null" 
                        v-model="oldPass"
                        color="brown"
                        prepend-icon="lock"
                        name="oldPassword"
                        :label="$t('changePassword.original-pass')"
                        :append-icon="show.oldPass ? 'visibility_off' : 'visibility'"
                        :type="show.oldPass ? 'input' : 'password'"
                        :rules="[rules.required]"
                        @click:append="show.oldPass = !show.oldPass"
                    ></v-text-field>
                    <v-text-field block clearable
                        v-if="pass === 'authcode'"
                        v-model="forgetAuth"
                        color="green"
                        name="forgetAuthCode"
                        :label="$t('changePassword.forgot-rules.emptyErr')"
                        :hint="$t('changePassword.forgot-rules.maxLengthErr')"
                        counter=5
                        :append-icon="show.forgetAuth ? 'visibility_off' : 'visibility'"
                        :type="show.forgetAuth ? 'input' : 'password'"
                        :rules="forgotRules"
                        @click:append="show.forgetAuth = !show.forgetAuth"
                        @paste="disablePasteCopyDrop"
                        @copy="disablePasteCopyDrop"
                        @drop="disablePasteCopyDrop"
                    ></v-text-field>
                    <br />
                    <v-text-field outline clearable required
                        v-model="newPass"
                        prepend-icon="lock"
                        name="newPassword"
                        :label="$t('changePassword.new-pass')"
                        :hint="$t('changePassword.pass-hint')"
                        counter=20
                        :append-icon="show.newPass ? 'visibility_off' : 'visibility'"
                        :type="show.newPass ? 'input' : 'password'"
                        :rules="[rules.required, rules.min]"
                        @click:append="show.newPass = !show.newPass"
                    ></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn large outline class="subheading" color="green" v-if="pass !== null" @click="back" data-cy="loginBackBtn">{{ $t('changePassword.back') }}</v-btn>
                <v-btn large block class="subheading" color="primary" @click="submit" data-cy="loginSubmitBtn">{{ $t('changePassword.submit') }}</v-btn>
            </v-card-actions>
            <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
            <message-dialog
                :show.sync="msgDialog.show"
                :msg="msgDialog.msg"
                :primary-btn="msgDialog.primaryBtn"
            ></message-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
import store from '@/store'
import axios from 'axios'
import WaitingDialog from '@/components/WaitingDialog.vue'
import WaitDialogControl from '@/mixins/WaitDialogControl.vue'
import MessageDialog from '@/components/MessageDialog.vue'
import MessageDialogControl from '@/mixins/MessageDialogControl.vue'
import { HostUrl, SideBtnType, routePage } from '@/store'
import { encrypt, getAxiosConfig, PWA_TOKEN} from '@/utils/AuthService'
import i18n from '@/i18n'

export default {
    name: 'change-password',
    components: { WaitingDialog, MessageDialog },
    mixins: [ WaitDialogControl, MessageDialogControl ],
    props: {
        pass: {
            type: String,
            default: null
        }
    },
    data() {
        //console.log('pass:'+this.pass)
        return {
            valid: false,
            show: {
                forgetAuth: this.pass && (this.pass.indexOf('authcode') !== -1),
                oldPass: false,
                newPass: false
            },
            routepage: 2,
            forgetAuth: '',
            oldPass: '',
            newPass: '',
            rules: {
                required: v => !!v || i18n.t('changePassword.rules.emptyErr'),
                min: v => (v && v.length >= 6) || i18n.t('changePassword.rules.minLengthErr'),
                max: v => (v && v.length <= 20) || i18n.t('changesPassword.rules.maxLengthErr')
            },
            forgotRules: [
                v => !!v || i18n.t('changePassword.forgot-rules.emptyErr'),
                v => (v && v.length === 5) || i18n.t('changePassword.forgot-rules.maxLengthErr')
            ]
        }
    },
    methods: {
        init() {
            this.show = {
                forgetAuth: this.pass && (this.pass.indexOf('authcode') !== -1),
                oldPass: false,
                newPass: false
            }
            this.forgetAuth = ''
            this.oldPass = ''
            this.newPass = ''
        },
        back() {
            this.init()
            this.$emit('back')
        },
        disablePasteCopyDrop(event) {
            event.preventDefault();
        },
        async updateByOldPassword() {
            let suburl = '/api/user/password';
            return await axios.post(HostUrl + suburl, {
                old_password: encrypt(this.oldPass),
                new_password: encrypt(this.newPass)
            }, getAxiosConfig())
        },
        async updateByForgetPass() {
            let suburl = '/api/user/password/pass';
            return await axios.post(HostUrl + suburl, {
                forget_pass: this.forgetAuth,
                new_password: encrypt(this.newPass)
            }, getAxiosConfig(PWA_TOKEN))
        },
        async submit() {
            if (this.$refs.form.validate()) {
                this.showDialog(i18n.t('changePassword.wait-change-pass'), '')
                try {
                    let response = (this.pass === null) ? await this.updateByOldPassword() : await this.updateByForgetPass()
                    if (response.data.code === 0) {
                        store.commit('setUserId', response.data.profile.id)
                        store.commit('setSnackBar', {
                            message: i18n.t('changePassword.change-success'),
                            color: 'success'
                        })
                        store.dispatch('changeUser', {
                            profile: response.data.profile,
                            token: response.data.token
                        })
                        this.init()
                        if (this.pass === null) this.$router.push(`/${i18n.locale}/menu/`)
                        else {
                            let page = parseInt(localStorage.getItem('pagehome'))
                            routePage(page)
                        }
                    } else {
                        console.log(response.data.message)
                        if(response.data.message === "密碼不正確") {
                            if (this.forgetAuth && this.forgetAuth.length !== 5 ) {
                                response.data.message = i18n.t('changePassword.auth-pass-incorrect')
                            } else
                                response.data.message = i18n.t('changePassword.old-pass-incorrect')
                        } else if (response.data.message.indexOf('驗證碼已過期') !== -1) {
                            response.data.message = i18n.t('changePassword.auth-overtime')
                            this.routepage = 1
                        } else if (response.data.message.indexOf('無效驗證碼') !== -1) {
                            response.data.message = i18n.t('changePassword.input-auth-fail')
                        }
                        this.showMsgDialog(response.data.message, i18n.t('changePassword.close'))
                    }
                } catch (error) {
                    this.showMsgDialog(i18n.t('changePassword.fail-change-pass'), i18n.t('changePassword.close'))
                } finally {
                    this.closeDialog()
                    if (this.routepage === 1) {
                        this.back()
                    }
                }
            }
        }
    },
    activated() {
        this.init()
        store.commit('setTitle', 'changePassword.nav-change-password')
        store.commit('setSideBtnType', SideBtnType.Back)
    }
}
</script>

<style scoped>
</style>
